import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faMapPin } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <div className='content'>
        <header className="App-header">
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
          <div className="Loading">
            <h1 className="LoadHeader"> Rongomaipapa Marae </h1>
            <h5 className="LoadSubtitle"> In Development </h5>
            <div className="SocialBar">
              <a href='mailto:secretary@rongomaipapa.com'>
                <FontAwesomeIcon icon ={faEnvelope} className="Icon" />
              </a>
              <a href='https://www.facebook.com/groups/1511408722458006'>
                <FontAwesomeIcon icon ={faFacebook} className="Icon" />
              </a>
              <a href='https://maps.app.goo.gl/vrmGZ4WWwRjDuGFcA'>
                <FontAwesomeIcon icon={faMapPin} className="Icon" />
              </a>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default App;